import { render, staticRenderFns } from "./CkPageFeedbackDetails.vue?vue&type=template&id=1b761fe6&"
import script from "./CkPageFeedbackDetails.vue?vue&type=script&lang=js&"
export * from "./CkPageFeedbackDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/travis/build/Hounslow-Connect/admin-v2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1b761fe6')) {
      api.createRecord('1b761fe6', component.options)
    } else {
      api.reload('1b761fe6', component.options)
    }
    module.hot.accept("./CkPageFeedbackDetails.vue?vue&type=template&id=1b761fe6&", function () {
      api.rerender('1b761fe6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/CkPageFeedbackDetails.vue"
export default component.exports